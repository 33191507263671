import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1524589205/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Android SDK reference `}<a style={{
        "borderBottom": "2px solid #0048ff"
      }} className="improve-docs" href="/select/sdks/android/reference-v1">{`v1`}</a>{` `}<a style={{
        "marginRight": "auto",
        "color": "#111"
      }} className="improve-docs" href="/select/sdks/android/reference-v2">{`v2`}</a></h1>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#class-fidel"
        }}>{`Fidel class`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#properties"
        }}>{`Properties`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#methods"
        }}>{`Methods`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#callbacks"
        }}>{`Callbacks`}</a></li>
    </ol>
    <h2>{`class Fidel`}</h2>
    <p>{`This class is designed as a facade, used to configure the card enrollment process, via many of its `}<a parentName="p" {...{
        "href": "#properties"
      }}>{`static properties`}</a>{`, and present `}<a parentName="p" {...{
        "href": "#methods"
      }}>{`the card linking flow`}</a>{`. It's also the class that provides `}<a parentName="p" {...{
        "href": "#callbacks"
      }}>{`callbacks`}</a>{` that might be useful for your application.`}</p>
    <h2>{`Properties`}</h2>
    <h3>{`Mandatory properties`}</h3>
    <p>{`These are properties that must be set correctly. In the case where one of these properties are not set or they are set incorrectly, the SDK will return an error immediately via its callback.`}</p>
    <h4>{`apiKey: String`}</h4>
    <p>{`This key is used to authenticate your Fidel API account. Get it from your Fidel API dashboard -> Account Settings -> SDK Keys section.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Important note: For security reasons, please DO NOT store the SDK Key in your codebase. Follow our `}<a parentName="p" {...{
          "href": "/select/sdks/security-guidelines"
        }}>{`SDK security guide`}</a>{` for detailed recommendations.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Note: If you use a `}<strong parentName="p">{`test SDK Key`}</strong>{`, your users can only enroll `}<a parentName="p" {...{
          "href": "/select/cards/#test-card-numbers"
        }}>{`test card numbers`}</a>{`.`}</p>
    </blockquote>
    <h4>{`programId: String`}</h4>
    <p>{`The program ID indicates the Fidel API program in which the cards will be enrolled. Get the program ID by navigating to the Fidel API dashboard -> Programs section -> Click on the ID of the program you want to use. Clicking on it will copy the ID in your pasteboard.`}</p>
    <h4>{`termsConditionsURL: String`}</h4>
    <p>{`You need to set your terms and conditions URL if you would like to:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`support all the countries that Fidel API supports`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`set a specific `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` set of countries AND include US or Canada in your set of allowed countries.`}</p>
      </li>
    </ol>
    <p>{`By setting this property we add a link to your Terms & Conditions in the consent text. The cardholder needs to read and agree with your terms, before enrolling a card.`}</p>
    <h3>{`Optional properties (but we recommend setting them)`}</h3>
    <p>{`The following properties are technically not mandatory to be set. However, in order to make your Loyalty use case work with your Transaction Select program, please consider setting them correctly.`}</p>
    <h4>{`companyName: String`}</h4>
    <p>{`By setting this property we customize the consent text, that the cardholder needs to read and agree with, before enrolling a card.`}</p>
    <p>{`The maximum number of characters allowed for this property is `}<inlineCode parentName="p">{`60`}</inlineCode>{`.`}</p>
    <h4>{`deleteInstructions: String`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`"going to your account settings"`}</inlineCode></p>
    <p>{`This text informs the cardholder how to opt out of transaction monitoring in your program. It is appended at the end of the consent text. The maximum number of characters allowed for this property is `}<inlineCode parentName="p">{`60`}</inlineCode>{`.`}</p>
    <h4>{`privacyURL: String?`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    <p>{`If you provide a value for this parameter, the card enrollment consent text will include a phrase that will provide the user with more privacy related information at the URL that you provide.`}</p>
    <p>{`When the value of this parameter remains `}<inlineCode parentName="p">{`null`}</inlineCode>{` no such phrase will be displayed in the card enrolling consent text.`}</p>
    <p>{`If you provide an invalid URL string, you will not be able to start the card enrollment flow. Instead you will receive an error via any of the callback methods that you used, immediately after attempting to start the card enrollment flow.`}</p>
    <h3>{`Optional properties`}</h3>
    <h4>{`supportedCardSchemes`}</h4>
    <p>{`Type: `}<inlineCode parentName="p">{`Set<CardScheme>`}</inlineCode></p>
    <p>{`Default value: `}<inlineCode parentName="p">{`setOf(CardScheme.VISA, CardScheme.MASTERCARD, CardScheme.AMERICAN_EXPRESS)`}</inlineCode></p>
    <p>{`Sets a list of supported card schemes. If a card scheme is supported, cardholders will be able to enroll their card. If a card scheme is not in the list, then the cardholders will see an error message while typing or pasting the unsupported card number.`}</p>
    <p>{`If you set a `}<inlineCode parentName="p">{`null`}</inlineCode>{` value, you will not be able to start the Fidel SDK enrollment flow. In this case, immediately after attempting to start the flow, you will receive an error.`}</p>
    <h4>{`enum class CardScheme`}</h4>
    <p>{`Cases:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`VISA`}</inlineCode>{`, `}<inlineCode parentName="li">{`MASTERCARD`}</inlineCode>{`, `}<inlineCode parentName="li">{`AMERICAN_EXPRESS`}</inlineCode></li>
    </ul>
    <h4>{`allowedCountries`}</h4>
    <p>{`Type: `}<inlineCode parentName="p">{`Set<Country>`}</inlineCode></p>
    <p>{`Default value: `}<inlineCode parentName="p">{`setOf(Country.CANADA, Country.IRELAND, Country.JAPAN, Country.SWEDEN, Country.UNITED_ARAB_EMIRATES, Country.UNITED_KINGDOM, Country.UNITED_STATES)`}</inlineCode></p>
    <p>{`Sets the list of countries that cardholders can pick to be the card issuing country. When two or more countries are set, cardholders will be able to select the card issuing country with our country selection UI.`}</p>
    <p>{`If you set a value with only one country (`}<inlineCode parentName="p">{`size`}</inlineCode>{` of this set == 1), the country selection UI will not be displayed in the card enrollment screen. The country that you set will be considered the card issuing country for all cards enrolled in your Fidel API program using the SDK.`}</p>
    <p>{`If you set an empty value, you will not be able to start the enrollment flow. Instead you will receive an error immediately after the attempt to start the enrollment flow.`}</p>
    <h5>{`enum class Country`}</h5>
    <p>{`Cases:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CANADA, IRELAND, JAPAN, SWEDEN, UNITED_ARAB_EMIRATES, UNITED_KINGDOM, UNITED_STATES`}</inlineCode></li>
    </ul>
    <h4>{`defaultSelectedCountry: Country`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`UNITED_KINGDOM`}</inlineCode></p>
    <p>{`Sets the `}<inlineCode parentName="p">{`Country`}</inlineCode>{` that will be selected by default when the user opens the card enrollment screen. If the `}<inlineCode parentName="p">{`defaultSelectedCountry`}</inlineCode>{` is not part of the `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` list, then the first country in the `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` list will be selected.`}</p>
    <h4>{`metaData: org.json.JSONObject?`}</h4>
    <p>{`This is a JSONObject that you can use to associate custom data with an enrolled card.`}</p>
    <p>{`We advise setting an `}<inlineCode parentName="p">{`"id"`}</inlineCode>{` key -> value pair in this JSONObject. Later, it might be useful for you to use our `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/list-cards-from-metadata-id"
      }}>{`List Cards from Metadata ID`}</a>{` API Endpoint to query for cards using this ID.`}</p>
    <p>{`Example of meta data that you can set:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`val jsonMetaData = JSONObject()
jsonMetaData.put("id", "this-is-the-metadata-id")
jsonMetaData.put("myUserId", "123")
jsonMetaData.put("customKey", "customValue")
Fidel.metaData = jsonMetaData
`}</code></pre>
    <p>{`You would receive a JSONObject that is equal to this one, after successfully enrolling a card using the SDK callback.`}</p>
    <h4>{`bannerImage: Bitmap?`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    <p>{`Will display the banner image that you set in this parameter at the top of the card details screen.`}</p>
    <p>{`The banner image will take the device's width, but it has a fixed height of 100 dp.
The image view has the `}<inlineCode parentName="p">{`android:scaleType="centerCrop"`}</inlineCode>{` attribute, which means that the banner image that you set will fill its entire predefined area.`}</p>
    <p>{`For the banner image that you can set, we suggest to use the aspect ratio of the smallest devices that you support. On wider devices, the banner image will be cropped from top and bottom sides. This is because of the `}<inlineCode parentName="p">{`android:scaleType="centerCrop"`}</inlineCode>{` attribute that we set for the image view.`}</p>
    <p>{`If a device that opens the SDK has 320dp in width, the aspect ratio of the image view would be `}<em parentName="p">{`320 : 100`}</em>{`.
If a device that opens the SDK has 475dp in width, the aspect ratio of your banner image would be `}<em parentName="p">{`475 : 100`}</em>{`.`}</p>
    <p>{`You need to provide the image for all screen densities.`}</p>
    <p>{`Depending on what you want to display in the banner image, you might need to experiment a bit to make sure that nothing important from the image is hidden. The most important information should be displayed in the centre of the banner image.`}</p>
    <h4>{`programName: String`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`"our"`}</inlineCode></p>
    <p>{`This value is used in the consent text when enrolling a card issued in United States or Canada. Please set it to a maximum of `}<inlineCode parentName="p">{`60`}</inlineCode>{` characters.`}</p>
    <h4>{`autoScan: Boolean`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`false`}</inlineCode></p>
    <p>{`When set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, automatically starts the card camera scanning UI. After card scanning is finalized, the user will go to the expected card enrollment screen, with the card details prefilled.`}</p>
    <h2>{`Methods`}</h2>
    <h3>{`present(context: Context)`}</h3>
    <p>{`Presents the card linking activity. We start our activity for obtaining a result. So you can also override the `}<inlineCode parentName="p">{`onActivityResult`}</inlineCode>{` function to get a result from the SDK's card linking Activity.`}</p>
    <h4>{`Parameters`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`startingActivity: Activity`}</inlineCode>{`: the `}<inlineCode parentName="li">{`Activity`}</inlineCode>{` that will start the card enrollment flow. In this activity you can override the `}<inlineCode parentName="li">{`onActivityResult`}</inlineCode>{` function and expect a result there. This is a `}<em parentName="li">{`mandatory`}</em>{` parameter.`}</li>
    </ul>
    <h3>{`setCardLinkingObserver(cardLinkingObserver: FidelCardLinkingObserver)`}</h3>
    <p>{`Parameter:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`cardLinkingObserver: FidelCardLinkingObserver`}</inlineCode>{`: the `}<inlineCode parentName="li">{`FidelCardLinkingObserver`}</inlineCode>{` that will be notified when card enrollment succeeds or fails. This is a `}<em parentName="li">{`mandatory`}</em>{` parameter.`}</li>
    </ul>
    <h2>{`Callbacks`}</h2>
    <p>{`The SDK provides callbacks capabilities via the following methods:`}</p>
    <h3>{`Setting a FidelCardLinkingObserver`}</h3>
    <p>{`You can set an observer via the `}<inlineCode parentName="p">{`Fidel.setCardLinkingObserver(cardLinkingObserver: FidelCardLinkingObserver)`}</inlineCode>{` function.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`FidelCardLinkingObserver`}</inlineCode>{` interface has two "callback" functions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`fun onCardLinkingSucceeded(linkResult: LinkResult)
fun onCardLinkingFailed(linkResultError: LinkResultError)
`}</code></pre>
    <p>{`Please check below the details of `}<inlineCode parentName="p">{`LinkResult`}</inlineCode>{` and `}<inlineCode parentName="p">{`LinkResultError`}</inlineCode>{`.`}</p>
    <h3>{`Overriding the onActivityResult function in your starting Activity`}</h3>
    <p>{`You start the card enrollment experience by calling the `}<inlineCode parentName="p">{`Fidel.present(yourActivity)`}</inlineCode>{` function. In that activity you can override the `}<inlineCode parentName="p">{`onActivityResult`}</inlineCode>{` function and get Fidel card enrollment results, as exemplified below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`override fun onActivityResult(requestCode: Int, resultCode: Int, data: Intent?) {
    super.onActivityResult(requestCode, resultCode, data)

    // Use this request code to identifier the result you're trying to handle
    if (requestCode == Fidel.FIDEL_LINK_CARD_REQUEST_CODE) {
        if (data != null && data.hasExtra(Fidel.FIDEL_LINK_CARD_RESULT_CARD)) {
            val linkResult = data.getParcelableExtra(Fidel.FIDEL_LINK_CARD_RESULT_CARD) as? LinkResult
            val linkError = linkResult?.error
            if (linkError != null) {
                Log.e(Fidel.FIDEL_DEBUG_TAG, "Error message = " + linkError.message)
            } else if (linkResult != null) {
                Log.d(Fidel.FIDEL_DEBUG_TAG, "The link ID = " + linkResult.expMonth)
            }
        }
    }
}
`}</code></pre>
    <p>{`Please check below the details of `}<inlineCode parentName="p">{`LinkResult`}</inlineCode>{` and `}<inlineCode parentName="p">{`LinkResultError`}</inlineCode>{`.`}</p>
    <h4>{`class LinkResult`}</h4>
    <p>{`This class represents a successful card enrollment/linking result.`}</p>
    <p>{`Properties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`id: String!`}</inlineCode>{`: The identifier of the card enrolled with your Fidel API program.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accountId: String!`}</inlineCode>{`: The Fidel API account identifier.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`programId: String!`}</inlineCode>{`: The identifier of the program that the card was enrolled into.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`created: String!`}</inlineCode>{`: The date when the card was linked. It is formatted as a string. The following is an example: `}<inlineCode parentName="li">{`"2021-05-19T12:37:55.278Z"`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`updated: String!`}</inlineCode>{`: The date when the card was updated. It is formatted as a string. The following is an example: `}<inlineCode parentName="li">{`"2021-05-19T12:37:55.278Z"`}</inlineCode>{`. `}<em parentName="li">{`Deprecated`}</em>{`: Please use `}<inlineCode parentName="li">{`created`}</inlineCode>{` instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`scheme: String!`}</inlineCode>{`: The enrolled card's scheme. Possible values are: `}<inlineCode parentName="li">{`"visa"`}</inlineCode>{`, `}<inlineCode parentName="li">{`"mastercard"`}</inlineCode>{`, `}<inlineCode parentName="li">{`"amex"`}</inlineCode>{`. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type: String!`}</inlineCode>{`: The type of card that was linked. Possible values are: `}<inlineCode parentName="li">{`"visa"`}</inlineCode>{`, `}<inlineCode parentName="li">{`"mastercard"`}</inlineCode>{`, `}<inlineCode parentName="li">{`"amex"`}</inlineCode>{`. `}<em parentName="li">{`Deprecated`}</em>{`: Please use `}<inlineCode parentName="li">{`scheme`}</inlineCode>{` instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mapped: Boolean!`}</inlineCode>{`: `}<em parentName="li">{`Deprecated`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`live: Boolean!`}</inlineCode>{`: This property will be `}<inlineCode parentName="li">{`true`}</inlineCode>{` when your Fidel API account is live and the card was enrolled in your `}<inlineCode parentName="li">{`live`}</inlineCode>{` Fidel API program. If the program that you enrolled the card into is not a `}<inlineCode parentName="li">{`live`}</inlineCode>{` one, then this property will be `}<inlineCode parentName="li">{`false`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`firstNumbers: String!`}</inlineCode>{`: If available, this property will be populated with the first 6 numbers of the enrolled card. To turn on or off receiving these numbers, please check your Fidel API account's settings.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`lastNumbers: String!`}</inlineCode>{`: If available, this property will be populated with the last 4 numbers of the enrolled card. To turn on or off receiving these numbers, please check your Fidel API account's settings.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`expYear: Int`}</inlineCode>{`: The expiration year of the enrolled card. The values are four digit year values (ex: 2031), `}<strong parentName="li">{`not`}</strong>{` shortened, two digit values (ex: 31).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`expMonth: Int`}</inlineCode>{`: The expiration month of the enrolled card. The values start with `}<inlineCode parentName="li">{`1`}</inlineCode>{` (January) and end with `}<inlineCode parentName="li">{`12`}</inlineCode>{` (December).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`countryCode: String!`}</inlineCode>{`: The country where the enrolled card was issued. Possible values: `}<inlineCode parentName="li">{`"GBR"`}</inlineCode>{` (Great Britain), `}<inlineCode parentName="li">{`"IRL"`}</inlineCode>{` (Ireland), `}<inlineCode parentName="li">{`"USA"`}</inlineCode>{` (United States of America), `}<inlineCode parentName="li">{`"SWE"`}</inlineCode>{` (Sweden), `}<inlineCode parentName="li">{`"JPN"`}</inlineCode>{` (Japan), `}<inlineCode parentName="li">{`"CAN"`}</inlineCode>{` (Canada).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`metaData: JSONObject?`}</inlineCode>{`: Custom data assigned to the enrolled card via the `}<inlineCode parentName="li">{`metaData`}</inlineCode>{` SDK property.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`error: LinkResultError?`}</inlineCode>{`: It contains an error, when the card enrollment response is not successful.`}</li>
    </ul>
    <h4>{`class LinkResultError`}</h4>
    <p>{`Represents an error during the card enrollment process. Properties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`message: String`}</inlineCode>{`: An error message explaining more details about the error. It is not localized.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`date: String`}</inlineCode>{`: The date and time when the error occurred, expressed as a `}<inlineCode parentName="li">{`String`}</inlineCode>{` value. Example value: `}<inlineCode parentName="li">{`2021-05-19T16:34:20.497Z`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`errorCode: LinkResultErrorCode`}</inlineCode>{`: Please check more details about `}<inlineCode parentName="li">{`LinkResultErrorCode`}</inlineCode>{` below.`}</li>
    </ul>
    <h4>{`enum LinkResultErrorCode`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ERROR_LINKING_CARD`}</inlineCode>{`: You'll receive an error with this code when receiving an error while attempting to link a card.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`USER_CANCELED`}</inlineCode>{`: The user canceled the card enrollment flow at any stage.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`INVALID_URL`}</inlineCode>{`: You'll receive an error with this code when you configured the `}<inlineCode parentName="li">{`termsConditionsURL`}</inlineCode>{` or `}<inlineCode parentName="li">{`privacyURL`}</inlineCode>{` with an invalid URL.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`STRING_OVER_THE_LIMIT`}</inlineCode>{`: You'll receive an error with this code when you configured a Fidel SDK parameter with too many characters. Some of the string parameters that we accept have limited number of characters. Please check that all parameters that you set are valid.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MISSING_MANDATORY_INFO`}</inlineCode>{`: You'll receive an error with this code when you did not provide a mandatory parameter. Please check that all mandatory parameters are set.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`NULL_STRING_TO_VALIDATE`}</inlineCode>{`: You'll receive an error with this code when you configured the Fidel SDK with a null URL when you actually needed to provide one.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      